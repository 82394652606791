import {useEffect, useState} from "react";
import {Box, Divider, Menu, MenuItem, TextField, Typography} from "@mui/material";

const LineStyle = {
    //border: '1px solid #dddddd',
    textAlign: 'left',
    padding: '2px',
    fontSize: '12px', // Adjust font size as needed
    whiteSpace: 'nowrap',
    color: '#71797E'
};

const Highlights = [
    {bgColor: '#7FFFD4'},
    {bgColor: '#E1C16E'},
    {bgColor: '#FFBF00'},
    {bgColor: '#F8C8DC'},
    {bgColor: '#E6E6FA'},
    {bgColor: '#FFFF8F'},
]

function getLineStyle(lineInfo) {

    let fgColor = null;
    let bgColor = lineInfo.bgColor;

    if (lineInfo.logLevel === "E")
        fgColor = '#C70039'

    if (lineInfo.logLevel === "W")
        fgColor = '#CD7F32'

    if (fgColor || bgColor) {
        return {
            ...LineStyle,
            color: fgColor,
            backgroundColor: bgColor,
        }
    }

    return LineStyle;
}

function UILogFile({logInfo}) {

    const [filteredLines, setFilteredLines] = useState([]);
    const [include, setInclude] = useState("");
    const [includeError, setIncludeError] = useState(false);
    const [contextMenu, setContextMenu] = useState(null);
    const [selectedText, setSelectedText] = useState('');
    const [excludes, setExcludes] = useState(() => {
        const storedExcludes = JSON.parse(localStorage.getItem('excludes'));
        return storedExcludes || [];
    });
    useEffect(() => {
        localStorage.setItem('excludes', JSON.stringify(excludes));
    }, [excludes]);
    const [highlights, setHighlights] = useState(() => {
        const storedStyles = JSON.parse(localStorage.getItem('highlights'));
        return storedStyles || [];
    });
    useEffect(() => {
        localStorage.setItem('highlights', JSON.stringify(highlights));
    }, [highlights]);

    useEffect(() => {

        setIncludeError(false)

        console.log("useEffect update filteredLines from [include, excludes, logInfo]")

        let lines = logInfo ? logInfo.lines : []

        let includeRegex;
        if (include.length > 0) {
            try {
                includeRegex = new RegExp(include, 'i');
            } catch (e) {
                setIncludeError(true)
            }
        }

        let _filteredLines = [];
        for (let lineIdx = 0; lineIdx < lines.length; lineIdx++) {

            let line = lines[lineIdx];

            let tag = line.tag || "";
            let message = line.message || "";
            let dateStr = line.dateStr || "";

            if (includeRegex) {
                let lineHasInclude = includeRegex.test(tag) ||
                    includeRegex.test(message) ||
                    includeRegex.test(dateStr);
                if (!lineHasInclude)
                    continue;
            }

            if (excludes.length > 0) {
                let lineHasExclude
                for (let excludeIdx = 0; excludeIdx < excludes.length; excludeIdx++) {
                    let exclude = excludes[excludeIdx];
                    let tagHasExclude = tag.includes(exclude);
                    let messageHasExclude = message.includes(exclude);
                    let dateHasExclude = dateStr.includes(exclude);
                    lineHasExclude = tagHasExclude || messageHasExclude || dateHasExclude;
                    if (lineHasExclude) {
                        break
                    }
                }
                if (lineHasExclude)
                    continue;
            }

            line.bgColor = null;
            for (let highlightIdx = 0; highlightIdx < highlights.length; highlightIdx++) {
                let highlight = highlights[highlightIdx];
                let tagHasHighlight = tag.includes(highlight.string);
                let messageHasHighlight = message.includes(highlight.string);
                let dateHasHighlight = dateStr.includes(highlight.string);
                let lineHasHighlight = tagHasHighlight || messageHasHighlight || dateHasHighlight;
                if (lineHasHighlight) {
                    line.bgColor = highlight.bgColor;
                    break
                }
            }


            _filteredLines.push(line)
        }

        console.log("_filteredLines", _filteredLines.length)
        setFilteredLines(_filteredLines)

    }, [include, excludes, highlights, logInfo]);

    const onRightClick = (event) => {
        event.preventDefault();
        const _selectedText = window.getSelection().toString();
        if (_selectedText) {
            setSelectedText(_selectedText);
            setContextMenu(
                contextMenu === null
                    ? {
                        mouseX: event.clientX + 2,
                        mouseY: event.clientY - 6,
                    }
                    : null,
            );
        }
    };


    function renderClearFilter(text, onClick) {
        return <>
            {text != null && <Typography
                component="span"
                variant="body2"
                style={{color: 'blue', textDecoration: 'underline', cursor: 'pointer'}}
                onClick={onClick}
            >
                {text}
            </Typography>}
        </>;
    }

    return (
        <>
            <Menu
                keepMounted
                open={contextMenu !== null}
                onClose={() => setContextMenu(null)}
                anchorReference="anchorPosition"
                anchorPosition={
                    contextMenu !== null
                        ? {top: contextMenu.mouseY, left: contextMenu.mouseX}
                        : undefined
                }
            >
                <MenuItem onClick={() => {
                    setInclude(selectedText);
                    setContextMenu(null);
                }}>Include</MenuItem>
                <MenuItem onClick={() => {
                    setExcludes([...excludes, selectedText]);
                    setContextMenu(null);
                }}>Exclude</MenuItem>
                <Divider/>
                {Highlights.map((Highlight) =>
                    <MenuItem
                        onClick={() => {
                            setHighlights([...highlights, {string: selectedText, bgColor: Highlight.bgColor}]);
                            setContextMenu(null);
                        }}
                        sx={{
                            backgroundColor: Highlight.bgColor,
                        }}
                    >
                        Highlight
                    </MenuItem>)}

            </Menu>

            <table style={{borderCollapse: 'collapse', width: '100%'}} onContextMenu={onRightClick}>
                <tbody>
                {filteredLines.map((_lineInfo, idx) => {

                        let style = getLineStyle(_lineInfo);

                        return <tr key={"Line:" + idx}>
                            <td style={style}>
                                {_lineInfo.dateStr ? _lineInfo.dateStr : "???"}
                            </td>
                            <td style={style}>
                                {_lineInfo.tag}
                            </td>
                            <td style={style}>
                                {_lineInfo.tag === "LogDownloadLink" ? (
                                    <a href={_lineInfo.message}>
                                        {_lineInfo.message}
                                    </a>
                                ) : (
                                    _lineInfo.message
                                )}


                            </td>
                        </tr>;
                    }
                )}
                </tbody>
            </table>

            {logInfo?.lines?.length > 0 && <Box
                sx={{
                    position: 'fixed',
                    bottom: 20,
                    right: 20,
                    backgroundColor: 'white',
                    color: 'white',
                    padding: 2,
                    borderRadius: 1,
                    boxShadow: 3,
                    width: '300px'
                }}
            >
                <TextField
                    fullWidth
                    value={include}
                    error={includeError}
                    label="Include"
                    onChange={(event) => {
                        setInclude(event.target.value)
                    }}
                >
                </TextField>

                {renderClearFilter((include.length > 0) ? ("Clear Include") : null, () => setInclude([]))}
                <label> </label>
                {renderClearFilter((excludes.length > 0) ? ("Clear " + excludes.length + " Excludes") : null, () => setExcludes([]))}
                <br/>
                {renderClearFilter((highlights.length > 0) ? ("Clear " + highlights.length + " Highlights") : null, () => setHighlights([]))}

            </Box>}
        </>
    );
}

export default UILogFile;