import {DayCode} from "./DayCode";

export class Log {


    static isUser(log, user) {
        return "" + log.user_id === "" + user?.id
    }

    static getSourceId(log) {

        let sourceId;
        if (log.device_name.includes(log.app_name)) {
            sourceId = log.device_name;
        } else {
            sourceId = log.app_name + " / " + log.device_name;
        }
        sourceId = sourceId.replace("ELEMNT", "")

        return sourceId
    }


    static isOnHour(log, startOfHour) {
        if (!log.startDate) return false;
        let startDate = new Date(log.startDate);
        let endDate = new Date(log.endDate);
        let endOfHour = new Date(startOfHour);
        endOfHour.setHours(endOfHour.getHours() + 1);
        return endDate >= startOfHour && startDate <= endOfHour;
    }

    static isOnFive(log, startOfFive) {
        if (!log.startDate) return false;
        let startDate = new Date(log.startDate);
        let endDate = new Date(log.endDate);
        let endOfFive = new Date(startOfFive);
        endOfFive.setMinutes(endOfFive.getMinutes() + 5);
        return endDate >= startOfFive && startDate <= endOfFive;
    }

    static formatDate_HHMMSS(date) {
        if (!date) return null;
        try {
            const h = date.getHours().toString().padStart(2, '0');
            const m = date.getMinutes().toString().padStart(2, '0');
            const s = date.getSeconds().toString().padStart(2, '0');
            const ms = date.getMilliseconds().toString().padStart(3, '0');
            return `${h}:${m}:${s}.${ms}`;
        } catch (e) {
            console.error("formatDate_HHMMSS", e, date)
            return "?"
        }
    }

    static formatDate_YYYYMMDD_HHMMSS(date) {
        if (!date) return null;
        const pad = (num) => String(num).padStart(2, '0');

        const y = date.getFullYear();
        const mo = pad(date.getMonth() + 1); // Months are zero-based
        const d = pad(date.getDate());
        const h = pad(date.getHours());
        const mi = pad(date.getMinutes());
        const s = pad(date.getSeconds());

        return `${y}${mo}${d}_${h}${mi}${s}`;
    }

    /** 2024.06.04 18:10:47+AEST */
    static parseDateStr1(dateString) {
        const els = dateString.split(/[.\s+:-]/);
        const str = `${els[0]}-${els[1]}-${els[2]}T${els[3]}:${els[4]}:${els[5]}`;
        return new Date(str);
    }

    /** dateId is not used yet. I am experimenting to see if it helps with local/utc timezone difficulties */
    static parseDateId1(dateString) {
        const els = dateString.split(/[.\s+:-]/);
        return `${els[0]}${els[1]}${els[2]}.${els[3]}${els[4]}${els[5]}.000`;
    }

    /** 06-07 13:35:05.961 */
    static parseDateStr2(dateString, year) {
        const els = (year + "-" + (dateString)).split(/[.\s+:-]/);
        const str = `${els[0]}-${els[1]}-${els[2]}T${els[3]}:${els[4]}:${els[5]}.${els[6]}`;
        return new Date(str);
    }

    /** dateId is not used yet. I am experimenting to see if it helps with local/utc timezone difficulties */
    static parseDateId2(year, dateString) {
        const els = (year + "-" + (dateString)).split(/[.\s+:-]/);
        return `${els[0]}${els[1]}${els[2]}.${els[3]}${els[4]}${els[5]}.${els[6]}`;
    }

    /** 2024.06.04 18:10:47+AEST VERBOSE [WFCloudManager:156] syncNow(reason:): wsm_ios.WFCloudAppSyncManager is syncing now */
    static regex1 = /^(\d{4}\.\d{2}\.\d{2} \d{2}:\d{2}:\d{2}\+\S+) (\w+) \[(\S+:)(\d+)] (.*)$/;

    /** 2024.06.04 18:10:47+AEST VERBOSE [WFCloudManager:156] syncNow(reason:): wsm_ios.WFCloudAppSyncManager is syncing now */
    static regex1_(line) {

        let match = line.match(this.regex1)
        if (!match) return null;
        let dateStr = match[1];
        let date = this.parseDateStr1(dateStr);
        // dateId is not used yet. I am experimenting to see if it helps with local/utc timezone difficulties
        let dateId = this.parseDateId1(dateStr);
        return {
            date: date, dateId: dateId, logLevel: this.toLogLevel(match[2]), tag: match[3], lineNumber: match[4], message: match[5],
        };
    }

    /** 06-07 13:35:05.877 6096 6096 W libprocessgroup: Controller io is not found */
    static regex2 = /^(\d{2}-\d{2} \d{2}:\d{2}:\d{2}.\d{3})\s+(\d+)\s+(\d+)\s+(\w+)\s+(\w+):\s+(.*)$/;

    /** 06-07 13:35:05.877 6096 6096 W libprocessgroup: Controller io is not found */
    static regex2_(line, year) {
        let match = line.match(this.regex2)
        if (!match) return null;
        let dateStr = match[1];
        let date = this.parseDateStr2(dateStr, year);
        // dateId is not used yet. I am experimenting to see if it helps with local/utc timezone difficulties
        let dateId = this.parseDateId2(dateStr, year);
        return {
            date: date, dateId: dateId, logLevel: this.toLogLevel(match[4]), tag: match[5], lineNumber: null, message: match[6],
        };
    }

    /**
     * 06-07 13:35:05.961   920  6017 V CmdQueue-BTLE: [593] add ROAMED6B31:WRITE-BFILE len=6
     * 06-14 08:38:59.559 1948 1948 V Prefs : [2] Prefs BApplication 1 records took 3 ms
     */
    static regex3 = /^(.{2}-.{2} .{2}:.{2}:.{2}\..{3})\s+(\d+)\s+(\d+)\s+(\w+)\s+(.+?):(.*)$/;

    /**
     * 06-07 13:35:05.961   920  6017 V CmdQueue-BTLE: [593] add ROAMED6B31:WRITE-BFILE len=6
     * 06-14 08:38:59.559 1948 1948 V Prefs : [2] Prefs BApplication 1 records took 3 ms
     */
    static regex3_(line, year) {

        let match = line.match(this.regex3)
        if (!match) return null;
        let dateStr = match[1];
        let date = this.parseDateStr2(dateStr, year);
        // dateId is not used yet. I am experimenting to see if it helps with local/utc timezone difficulties
        let dateId = this.parseDateId2(dateStr, year);
        return {
            date: date, dateId: dateId, logLevel: this.toLogLevel(match[4]), tag: match[5], lineNumber: null, message: match[6],
        };
    }

    /**
     * WAIOS logs Kotlin Common weird
     * 2025.02.01 16:54:13+CST VERBOSE [:0] : [KCloudBikingProfileSyncManager] [259] syncSoonInternal cancelling previous pending job
     * 2025.02.01 16:54:13+CST VERBOSE [:0] : [KCloudPlanSyncManager[PLANS]] [27395] onPreSync disabling for now
     */
    static regex4 = /^(\d{4}\.\d{2}\.\d{2} \d{2}:\d{2}:\d{2}\+\S+) (\w+) \[:0] : \[(\S+)] \[(\d+)] (.*)$/;

    /**
     * 2025.02.01 16:54:13+CST VERBOSE [:0] : [KCloudBikingProfileSyncManager] [259] syncSoonInternal cancelling previous pending job
     * 2025.02.01 16:54:13+CST VERBOSE [:0] : [KCloudPlanSyncManager[PLANS]] [27395] onPreSync disabling for now
     */
    static regex4_(line, year) {

        let match = line.match(this.regex4)
        if (!match) return null;
        let dateStr = match[1];
        let date = this.parseDateStr2(dateStr, year);
        let dateId = this.parseDateId2(dateStr, year);
        return {
            date: date, dateId: dateId, logLevel: this.toLogLevel(match[2]), tag: match[3], lineNumber: null, message: match[5],
        };
    }


    static toLogLevel(str) {

        str = str.toLowerCase();
        if (str === "v" || str.includes("ver"))
            return "V"
        if (str === "d" || str.includes("deb"))
            return "D"
        if (str === "i" || str.includes("inf"))
            return "I"
        if (str === "w" || str.includes("warn"))
            return "W"
        if (str === "e" || str.includes("err"))
            return "E"
        return "V"
    }

    static getFilenameFromUrl = (url) => {
        // Create a new URL object
        const urlObject = new URL(url);

        // Extract the pathname
        const pathname = urlObject.pathname;

        // Get the filename by splitting the pathname and getting the last segment
        return pathname.split('/').pop();
    };

    static excludes = ["com.j256.ormlite.stmt"]

    static parse(log, text, minDate, maxDate) {

        console.log("log", log)
        console.log("minDate", Log.formatDate_YYYYMMDD_HHMMSS(minDate))
        console.log("maxDate", Log.formatDate_YYYYMMDD_HHMMSS(maxDate))

        const lines = text ? text.split(/\r?\n/) : ["ERROR: no lines"]

        let linesLen = lines.length;

        let logInfo = {
            lines: []
        }

        // Somn log lines don't include the year... so we borrow the year from the daycode
        const year = Math.floor(log.dayCode / 10000);

        this.addLineInfo({date: null, tag: "LogDownloadLink", message: log.file.url}, "Logrrator:downloadLink", logInfo);

        let lastDate = log.startDate;

        for (let i = 0; i < linesLen; i++) {

            let lineId = log.id + ":" + i;

            let line = lines[i];

            let exclude = false;
            for (let j = 0; j < this.excludes.lines; j++) {
                if (line.includes(this.excludes[i])) {
                    exclude = true;
                    break
                }
            }

            if (exclude)
                continue

            let lineInfo = this.regex1_(line);
            if (!lineInfo)
                lineInfo = this.regex2_(line, year);
            if (!lineInfo)
                lineInfo = this.regex3_(line, year);
            if (!lineInfo)
                lineInfo = this.regex4_(line, year);
            if (!lineInfo)
                lineInfo = {date: lastDate, message: line,};

            let isAfterMinDate = !minDate || lineInfo.date >= minDate;
            let isBeforeMaxDate = !maxDate || lineInfo.date <= maxDate;

            if (isAfterMinDate && isBeforeMaxDate) {
                this.addLineInfo(lineInfo, lineId, logInfo);
            }

            lastDate = lineInfo.date

        }

        return logInfo;
    }

    static addLineInfo(lineInfo, lineId, logInfo) {

        logInfo.lines.push(lineInfo)

        lineInfo.id = lineId;

        // This is what gets rendered to the table
        lineInfo.dateStr = this.formatDate_HHMMSS(lineInfo.date)

        if (!lineInfo.dateStr) {
            // console.log("addLineInfo no dateStr", lineInfo)
        }

        if (lineInfo.date) {
            if (!logInfo.startDate)
                logInfo.startDate = lineInfo.date;
            logInfo.endDate = lineInfo.date;
        }

        // dateId is not used yet. I am experimenting to see if it helps with local/utc timezone difficulties
        if (lineInfo.dateId) {
            if (!logInfo.startDateId)
                logInfo.startDateId = lineInfo.dateId;
            logInfo.endDateId = lineInfo.dateId;
        }
    }

    static getDayCode(log) {

        const startDate = log.startDate; // This value comes from the first log line in the log file - it's the best
        const startTimeStr = log.start_time; // This value comes from the CLOUD metadata
        const updatedAtStr = log.updated_at; // This value comes from the CLOUD metadata
        let filename = Log.getFilenameFromUrl(log.file?.url);

        if (startDate) {
            return DayCode.fromDate(startDate);
        } else if (filename) {
            return DayCode.fromFilename(filename)
        } else if (startTimeStr) {
            return DayCode.fromDateStr(startTimeStr);
        } else if (updatedAtStr) {
            return DayCode.fromDateStr(updatedAtStr);
        } else {
            return 0;
        }
    }
}

