import {openDB} from "idb/build/index";

const DB_NAME = 'Wahoo-Logrrator';
const LOGS = 'Logs';

export class Database {


    static getDb() {
        return openDB(DB_NAME, 2, {
            upgrade(db) {
                db.createObjectStore(LOGS);
            },
        });
    }

    static async saveLogText(logId, text) {
        console.log("saveTextFile", logId, text.length / 1000, "kb")
        const db = await this.getDb();
        await db.put(LOGS, text, logId);
    }

    static async loadLogText(logId) {
        const db = await this.getDb();
        return await db.get(LOGS, logId);
    }


    static reset() {

        const request = indexedDB.open(DB_NAME);

        request.onupgradeneeded = function (event) {
            const db = event.target.result;
            const objectStoreNames = Array.from(db.objectStoreNames);

            objectStoreNames.forEach((storeName) => {
                db.deleteObjectStore(storeName);
                console.log(`reset ${storeName}`);
            });
        };

        request.onsuccess = function (event) {
            const db = event.target.result;
            db.close();
            console.log('reset OK');
        };

        request.onerror = function (event) {
            console.error("reset FAILED", event);
        };
    }

}