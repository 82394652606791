export class DayCode {

    static fromFilename(filename) {
        if (!filename) return 0
        let date = this.parseDateFromFilename(filename);
        return this.fromDate(date)

    }

    static fromDateStr(dateStr) {
        if (!dateStr) return 0
        let date = new Date(dateStr);
        return this.fromDate(date)

    }

    static fromDate(date) {
        if (!date) return 0
        try {
            if (date instanceof Date) {
                let yr = date.getFullYear();
                let mo = date.getMonth() + 1;
                let da = date.getDate();
                return yr * 10000 + mo * 100 + da
            } else {
                return this.fromDateStr(date)
            }
        } catch (e) {
            console.error("fromDate", e, date)
            return 0
        }
    }

    static parseDateFromFilename(filename) {

        let date;

        // Try BoltApp-20240911_000737-_-.txt
        let filenameEls = filename.split('-');
        if (filenameEls.length > 1) {
            date = this.parseDateFromStr(filenameEls[1])
            //console.log("parseDateFromFilename date from filename (-)", filename, date)
        } else {
            // Try BoltApp.20240910.175216.txt
            filenameEls = filename.split('.');
            if (filenameEls.length > 2) {
                date = this.parseDateFromStr(filenameEls[1] + "_" + filenameEls[2])
                //console.log("parseDateFromFilename date from filename (.)", filename, date)
            } else {
                console.error("parseDateFromFilename cannot get date from filename", filename)
                date = null;
            }
        }

        return date

    }

    /** YYYYMMDD_HHMMSS */
    static parseDateFromStr(dateStr) {
        try {
            const year = parseInt(dateStr.substring(0, 4), 10); // YYYY
            const month = parseInt(dateStr.substring(4, 6), 10) - 1; // MM (zero-based for JS Date)
            const day = parseInt(dateStr.substring(6, 8), 10); // DD
            const hours = parseInt(dateStr.substring(9, 11), 10); // HH
            const minutes = parseInt(dateStr.substring(11, 13), 10); // MM
            const seconds = parseInt(dateStr.substring(13, 15), 10); // SS
            return new Date(year, month, day, hours, minutes, seconds);
        } catch (e) {
            console.error("parseDateFromStr", e)
            return null
        }
    }


}