import './App.css';
import UIMain from "./ui/UIMain";

function App() {

    return (
        <>
            <UIMain/>
        </>
    );
}

export default App;
