import {useEffect, useState} from "react";
import {Log} from "../data/Log";
import {Button, IconButton, Tooltip} from "@mui/material";
import {Database} from "../data/Database";
import RefreshIcon from "@mui/icons-material/Refresh";
import {DayCode} from "../data/DayCode";

function UILogSelector({user, logs, onShowLogInfo, onRefreshDayLogs}) {

    const [dayInfos, setDayInfos] = useState([]);
    const [selectedDayInfo, setSelectedDayInfo] = useState(null);

    const [sourceInfos, setSourceInfos] = useState([]);
    const [selectedSourceInfo, setSelectedSourceInfo] = useState(null);

    const [hourInfos, setHourInfos] = useState([]);
    const [selectedHourInfo, setSelectedHourInfo] = useState(null);

    const [fiveInfos, setFiveInfos] = useState([]);
    const [selectedFiveInfo, setSelectedFiveInfo] = useState(null);

    const [crashInfos, setCrashInfos] = useState([]);
    const [selectedCrashInfo, setSelectedCrashInfo] = useState(null);

    /** DAYS (depends on all logs) */
    useEffect(() => {

        console.log("useEffect update dayInfos by [user, logs]")
        console.log("useEffect user", user)
        console.log("useEffect", logs?.length, "logs")

        setSelectedDayInfo(null)
        setSelectedSourceInfo(null)
        setSelectedHourInfo(null)
        setSelectedCrashInfo(null)
        setSelectedFiveInfo(null)
        onShowLogInfo(null)

        let _dayInfos = [];
        const today = new Date();
        today.setHours(0, 0, 0, 0);

        let dayInfosWithLogs = 0;
        for (let i = 14; i >= 0; i--) {

            let dayDate = new Date();
            dayDate.setDate(today.getDate() - i);

            let dayCode = DayCode.fromDate(dayDate);

            let dayLogs = [];
            for (let j = 0; j < logs.length; j++) {
                let log = logs[j];

                let isUser = Log.isUser(log, user);
                let isOnDay = dayCode === log.dayCode

                let onDay = isUser && isOnDay;

                if (onDay) {
                    dayLogs.push(log);
                }
            }

            if (dayLogs.length > 0) dayInfosWithLogs++;

            // https://htmlcolorcodes.com/colors/shades-of-green/
            _dayInfos.push({
                date: dayDate, dateStr: formatDayInfo(dayDate), logs: dayLogs,
            });
        }

        console.log("useEffect update dayInfosWithLogs", dayInfosWithLogs)
        setDayInfos(_dayInfos)

    }, [user, logs]);

    /** SOURCES (depends on selected day) */
    useEffect(() => {

        console.log("useEffect update sourceInfos by [dayInfos, selectedDayInfo]")
        if (!selectedDayInfo) {

            setSourceInfos([]);
            setSelectedSourceInfo(null);

            setHourInfos([])
            setSelectedHourInfo(null)

            setCrashInfos([])
            setSelectedCrashInfo(null)

            setFiveInfos([])
            setSelectedFiveInfo(null)

            return
        }

        let logsToSearch = selectedDayInfo.logs;

        let _sourceInfos = [];

        for (let i = 0; i < logsToSearch.length; i++) {
            let log = logsToSearch[i];

            let sourceId;
            let isCrash = log.filename.includes("CRASH");
            if (isCrash) {
                sourceId = "CRASH"
            } else {
                sourceId = Log.getSourceId(log);
            }


            let sourceInfo = _sourceInfos.find(_sourceInfo => _sourceInfo.sourceId === sourceId);
            if (!sourceInfo) {
                sourceInfo = {
                    sourceId: sourceId, logs: [], isCrash: isCrash
                }
                _sourceInfos.push(sourceInfo);
            }
            sourceInfo.logs.push(log);
        }

        console.log("useEffect update sourceInfos", _sourceInfos.length)
        setSourceInfos(_sourceInfos);

    }, [dayInfos, selectedDayInfo]);

    /** HOURS (depends on day >> source) */
    useEffect(() => {

        console.log("useEffect update hourInfos by [sourceInfos, selectedSourceInfo]")

        if (!selectedSourceInfo) {

            setHourInfos([])
            setSelectedHourInfo(null)

            setCrashInfos([])
            setSelectedCrashInfo(null)

            setFiveInfos([])
            setSelectedFiveInfo(null)

            return
        }

        if (selectedSourceInfo.isCrash)
            return

        let logsToSearch = selectedSourceInfo.logs;

        let _hourInfos = [];
        for (let i = 0; i < 24; i++) {

            let hourDate = new Date(selectedDayInfo.date);
            hourDate.setHours(i, 0, 0, 0);

            let _hourLogs = [];

            for (let j = 0; j < logsToSearch.length; j++) {
                let log = logsToSearch[j];

                if (!Log.isUser(log, user)) continue
                if (!Log.isOnHour(log, hourDate)) continue;

                //console.log("hour", i, "has log")
                _hourLogs.push(log);
            }

            _hourInfos.push({
                date: hourDate, dateStr: formatHourInfo(hourDate), logs: _hourLogs
            })
        }

        console.log("useEffect update hourInfos", _hourInfos.length)
        setHourInfos(_hourInfos)

    }, [sourceInfos, selectedSourceInfo]);

    /** FIVE (depends on day >> source >> hour) */
    useEffect(() => {

        console.log("useEffect update fiveInfos by [hourInfos, selectedHourInfo]")
        console.log("selectedHourInfo", selectedHourInfo)

        if (!selectedHourInfo) {
            setFiveInfos([])
            setSelectedFiveInfo(null);
            return
        }

        let logsToSearch = selectedHourInfo.logs;

        let _fiveInfos = [];
        for (let i = 0; i < 12; i++) {

            let fiveDate = new Date(selectedHourInfo.date);
            fiveDate.setMinutes(i * 5, 0, 0);

            let _fiveLogs = [];

            for (let j = 0; j < logsToSearch.length; j++) {
                let log = logsToSearch[j];

                if (!Log.isUser(log, user)) continue
                let onFive = Log.isOnFive(log, fiveDate);
                if (!onFive) continue;

                //console.log("five", i, "has log")
                _fiveLogs.push(log);
            }

            _fiveInfos.push({
                date: fiveDate, dateStr: formatFiveInfo(fiveDate), logs: _fiveLogs
            })
        }

        console.log("useEffect update fiveInfos", _fiveInfos.length)
        setFiveInfos(_fiveInfos)

    }, [hourInfos, selectedHourInfo]);

    useEffect(() => {
        console.log("useEffect update onShowLogs by [selectedFiveInfo]")
        if (selectedFiveInfo) {
            setSelectedCrashInfo(null)
        }
        loadLogs();
    }, [selectedFiveInfo]);

    useEffect(() => {
        console.log("useEffect update onShowLogs by [selectedCrashInfo]")
        if (selectedCrashInfo) {
            setSelectedHourInfo(null)
            setSelectedFiveInfo(null)
        }
        loadCrashLog();
    }, [selectedCrashInfo]);

    /** CRASHES (depends on day >> source) */
    useEffect(() => {

        console.log("useEffect update crashInfos by [sourceInfos, selectedSourceInfo]")

        if (!selectedSourceInfo) {
            setCrashInfos([])
            setSelectedCrashInfo(null)
            setFiveInfos([])
            setSelectedFiveInfo(null)
            return
        }

        if (!selectedSourceInfo.isCrash)
            return

        let logsToSearch = selectedSourceInfo.logs;

        let _crashInfos = [];
        for (let i = 0; i < logsToSearch.length; i++) {

            let log = logsToSearch[i];

            _crashInfos.push({
                name: log.filename, log: log
            })
        }

        console.log("useEffect update crashInfos", _crashInfos.length)
        setCrashInfos(_crashInfos)

    }, [sourceInfos, selectedSourceInfo]);

    async function loadLogs() {

        let startDate = new Date(selectedFiveInfo?.date)
        let logs = selectedFiveInfo?.logs
        if (!startDate || !logs) {
            onShowLogInfo(null)
            return
        }

        logs = logs.sort((a, b) => {
            const dateA = new Date(a.start_time);
            const dateB = new Date(b.start_time);
            return dateA - dateB;
        });

        let logInfo = {
            lines: []
        }

        console.log("loadLogs", logs.length, "logs")

        for (let i = 0; i < logs.length; i++) {

            let log = logs[i];

            console.log("loadLogs load", log.id)

            let text = await Database.loadLogText(log.id,);

            let endDate = new Date(startDate);
            endDate.setMinutes(endDate.getMinutes() + 5);
            let _logInfo = Log.parse(log, text, startDate, endDate);
            console.log("loadLogs loaded", log.id, (text.length / 1000) + "kb", _logInfo.lines.length + "lines")

            if (!logInfo.startDate) logInfo.startDate = _logInfo.startDate;
            logInfo.endDate = _logInfo.endDate;

            logInfo.lines.push(..._logInfo.lines)
            console.log("loadLogs accum", logInfo.lines.length + "lines")
        }

        onShowLogInfo(logInfo)
    }

    async function loadCrashLog() {

        if (!selectedCrashInfo) {
            onShowLogInfo(null)
            return
        }

        console.log("loadCrashLog", selectedCrashInfo.name)

        let log = selectedCrashInfo.log

        let logInfo = {
            lines: []
        }

        console.log("loadCrashLog load", log.id)

        let text = await Database.loadLogText(log.id,);

        let _logInfo = Log.parse(log, text);
        console.log("loadCrashLog loaded", log.id, (text.length / 1000) + "kb", _logInfo.lines.length + "lines")

        if (!logInfo.startDate) logInfo.startDate = _logInfo.startDate;
        logInfo.endDate = _logInfo.endDate;

        logInfo.lines.push(..._logInfo.lines)
        console.log("loadCrashLog accum", logInfo.lines.length + "lines")

        onShowLogInfo(logInfo)
    }

    function formatDayInfo(date) {
        const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        const day = date.getDate();
        const month = months[date.getMonth()];
        return `${day}${month}`;
    }

    function formatHourInfo(date) {
        let hours = date.getHours();
        return `${hours}`;
    }

    function formatFiveInfo(date) {

        let mins = date.getMinutes();
        return `${mins}`;
    }

    const tdStyle = {
        // border: '1px solid #ddd',
        textAlign: 'center', padding: '2px', fontSize: '12px', // Adjust font size as needed
        whiteSpace: 'nowrap',
    }

    const buttonStyle = {
        paddingTop: '2px', paddingBottom: '2px', paddingLeft: '5px', paddingRight: '5px', minWidth: 0
    }

    function getSourceColor(sourceInfo) {
        return sourceInfo.isCrash ? "error" : "primary";
    }

    return <>

        {/*DAYS*/}
        {user && <div style={{display: 'flex', justifyContent: 'center', width: '100%'}}>
            <table style={{
                width: '100%', borderCollapse: 'collapse',
            }}>
                <tbody>
                <tr>

                    {dayInfos.map((_dayInfo, idx) => <td key={"Day:" + idx} style={tdStyle}>

                        <Tooltip title={"Day has " + _dayInfo.logs.length + " logs"}>
                            <>
                                <Button
                                    variant={_dayInfo === selectedDayInfo ? "contained" : "outlined"}
                                    value={_dayInfo}
                                    disabled={_dayInfo.logs.length === 0}
                                    sx={buttonStyle}
                                    onClick={() => {
                                        setSelectedDayInfo(_dayInfo)
                                        setSelectedSourceInfo(null)
                                    }}
                                >
                                    {_dayInfo.dateStr}
                                </Button>

                                <IconButton
                                    onClick={() => {
                                        onRefreshDayLogs(_dayInfo)
                                    }}
                                    size="small"
                                    color="primary"
                                    sx={{borderRadius: '50%'}}
                                >
                                    <RefreshIcon fontSize="small"/>
                                </IconButton>
                            </>
                        </Tooltip>


                    </td>)}

                </tr>
                </tbody>
            </table>


        </div>}

        {/*SOURCES*/}
        {selectedDayInfo && <>
            <div style={{display: 'flex', justifyContent: 'center', width: '100%'}}>
                <table style={{
                    width: '100%', borderCollapse: 'collapse',
                }}>
                    <tbody>
                    <tr>
                        {sourceInfos.map((_sourceInfo, idx) => <td key={"Source:" + idx} style={tdStyle}>
                            <Tooltip title={
                                <div>
                                    <strong>Source has {_sourceInfo.logs.length} logs</strong> <br/>
                                    {_sourceInfo.logs.map((_log, logIdx) =>
                                        <div>{_log.filename}</div>
                                    )}
                                </div>
                            }>
                                <div>
                                    <Button
                                        variant={_sourceInfo === selectedSourceInfo ? "contained" : "outlined"}
                                        color={getSourceColor(_sourceInfo)}
                                        value={_sourceInfo}
                                        sx={buttonStyle}
                                        onClick={() => {
                                            setSelectedSourceInfo(_sourceInfo)
                                            setSelectedHourInfo(null)
                                            setSelectedCrashInfo(null)
                                        }}>
                                        {_sourceInfo.sourceId}
                                    </Button>
                                </div>
                            </Tooltip>
                        </td>)}

                    </tr>
                    </tbody>
                </table>

            </div>

        </>}

        {/*HOURS*/}
        {selectedSourceInfo && !selectedSourceInfo.isCrash && <div style={{display: 'flex', justifyContent: 'center', width: '100%'}}>
            <table style={{
                width: '100%', borderCollapse: 'collapse',
            }}>
                <tbody>
                <tr>

                    {hourInfos.map((_hourInfo, idx) => <td key={"Hour:" + idx} style={tdStyle}>
                        <Tooltip title={"Hour has " + _hourInfo.logs.length + " logs"}>
                                <span><Button
                                    variant={_hourInfo === selectedHourInfo ? "contained" : "outlined"}
                                    value={_hourInfo}
                                    disabled={_hourInfo.logs.length === 0}
                                    sx={buttonStyle}
                                    onClick={() => {
                                        setSelectedHourInfo(_hourInfo)
                                        setSelectedFiveInfo(null)
                                    }}
                                >
                                    {_hourInfo.dateStr}
                                </Button></span>
                        </Tooltip>

                    </td>)}

                </tr>
                </tbody>
            </table>
        </div>}

        {/*FIVE MIN PERIODS*/}
        {selectedHourInfo && <div style={{display: 'flex', justifyContent: 'center', width: '100%'}}>
            <table style={{
                width: '100%', borderCollapse: 'collapse',
            }}>
                <tbody>
                <tr>

                    {fiveInfos.map((_fiveInfo, idx) => <td key={"Five:" + idx} style={tdStyle}>
                        <Tooltip title={"This 5min has " + _fiveInfo.logs.length + " logs"}>
                                <span><Button
                                    variant={_fiveInfo === selectedFiveInfo ? "contained" : "outlined"}
                                    value={_fiveInfo}
                                    disabled={_fiveInfo.logs.length === 0}
                                    sx={buttonStyle}
                                    onClick={() => {
                                        setSelectedFiveInfo(_fiveInfo)
                                    }}
                                >
                                    {_fiveInfo.dateStr}
                                </Button></span>
                        </Tooltip>

                    </td>)}

                </tr>
                </tbody>
            </table>
        </div>}

        {/*CRASHS*/}
        {selectedSourceInfo && selectedSourceInfo.isCrash && <div style={{display: 'flex', justifyContent: 'center', width: '100%'}}>
            <table style={{
                width: '100%', borderCollapse: 'collapse',
            }}>
                <tbody>
                <tr>

                    {crashInfos.map((_crashInfo, idx) => <td key={"Crash:" + idx} style={tdStyle}>
                        <>
                            <Button
                                variant={_crashInfo === selectedCrashInfo ? "contained" : "outlined"}
                                color={"error"}
                                value={_crashInfo}
                                sx={buttonStyle}
                                onClick={() => {
                                    setSelectedCrashInfo(_crashInfo)
                                    setSelectedFiveInfo(null)
                                }}
                            >
                                {_crashInfo.name}
                            </Button>
                        </>

                    </td>)}

                </tr>
                </tbody>
            </table>
        </div>}

    </>;
}

export default UILogSelector;