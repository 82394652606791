import {Box, IconButton, InputAdornment, Modal, TextField} from "@mui/material";
import UIDivider from "./UIDivider";
import {UIModal} from "./UIModal";
import {useState} from "react";
import {Cloud} from "../data/Cloud";
import UIUserTable from "./UIUserTable";
import ClearIcon from '@mui/icons-material/Clear';
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DoneIcon from '@mui/icons-material/Done';

function UIModalUsersCfg({open, users, authUser, setUsers}) {

    const [userSearchStr, setUserSearchStr] = useState("");
    const [isSearchMode, setIsSearchMode] = useState(false);
    const [userSearchResults, setUserSearchResults] = useState([]);

    function onUserSearchClicked() {

        if (userSearchStr.length < 3) {
            console.log("onUserSearchClicked (too short to search)", userSearchStr)
            return
        }

        console.log("onUserSearchClicked", userSearchStr)

        Cloud.fetchUsers(userSearchStr, authUser, (users) => {

            let _searchResults = []

            if (Array.isArray(users)) {

                console.log("onUserSearchClicked jUsers", users)

                users.forEach((user) => {

                    let id = user.id
                    let name = user.first + " " + user.last
                    name = name.trim()
                    let email = user.email

                    if (id && name && email) {
                        console.log("onUserSearchClicked add", id, name, email)
                        _searchResults.push({"id": id, "name": name, "email": email, isProduction: authUser.isProduction})
                    } else {
                        console.log("onUserSearchClicked bad", id, name, email)
                    }

                });
            } else {
                console.error("onUserSearchClicked bad jUsers", users)
            }

            setUserSearchResults(_searchResults)
        })

    }

    function onAddUserClicked(user) {
        console.log("onAddUserClicked", user)
        setUsers([...users, user])
        setIsSearchMode(false)
    }

    function onDelUserClicked(user) {
        console.log("onDelUserClicked", user)
        setUsers(users.filter((_user) => _user.id !== user.id));
    }


    function saveUsersAndClose() {
        setUsers(users, true)
    }

    let filteredUsers = users.filter((_user) => authUser && _user.isProduction === authUser.isProduction);
    let filteredUserSearchResults = userSearchResults.filter((_user) => authUser && _user.isProduction === authUser.isProduction);


    return (
        <>

            <Modal
                open={open && !isSearchMode}
                onClose={saveUsersAndClose}
            >

                <Box sx={UIModal.style}>

                    <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>

                        <h3>Configure Users</h3>

                        <div>
                            <IconButton
                                onClick={() => {
                                    setIsSearchMode(true)
                                }}><PersonAddIcon/>
                            </IconButton>
                            <IconButton
                                onClick={() => {
                                    saveUsersAndClose();
                                }}><DoneIcon/>
                            </IconButton>


                        </div>

                    </div>

                    <UIUserTable users={filteredUsers} actionStr="del" onActionClicked={onDelUserClicked}></UIUserTable>

                    <UIDivider/>
                    <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end'}}>

                    </div>


                </Box>


            </Modal>

            <Modal
                open={open && isSearchMode}
                onClose={() => {
                    setIsSearchMode(false)
                }}
            >

                <Box sx={UIModal.style}>

                    <div style={{display: 'flex', alignItems: 'center'}}>

                        <IconButton
                            onClick={() => {
                                setIsSearchMode(false)
                            }}><ArrowBackIcon/>
                        </IconButton>

                        <h3 style={{marginLeft: '8px'}}>Add User</h3>
                    </div>

                    <div>
                        <TextField
                            fullWidth
                            label="Search"
                            value={userSearchStr}
                            onChange={(event) => {
                                setUserSearchStr(event.target.value)
                            }}
                            onKeyDown={(event) => {
                                if (event.key === 'Enter') {
                                    event.preventDefault(); // Prevent the default action if needed
                                    onUserSearchClicked();
                                }
                            }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        {userSearchStr && ( // Show the clear button only when there is text in the field
                                            <IconButton onClick={() => {
                                                setUserSearchStr("")
                                            }} edge="end">
                                                <ClearIcon/>
                                            </IconButton>
                                        )}
                                    </InputAdornment>
                                ),
                            }}
                        />

                    </div>

                    <UIDivider/>
                    <UIUserTable users={filteredUserSearchResults} actionStr="add" onActionClicked={onAddUserClicked}></UIUserTable>


                </Box>

            </Modal>
        </>
    );
}

export default UIModalUsersCfg;