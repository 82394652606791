import {Backdrop, Box, Button, FormControl, FormControlLabel, Modal, Radio, RadioGroup, TextField} from "@mui/material";
import UIDivider from "./UIDivider";
import {useState} from "react";
import {Cloud} from "../data/Cloud";
import {UIModal} from "./UIModal";
import { styled } from '@mui/system';

function UIModalLogin({open, onComplete}) {


    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [server, setServer] = useState("P");
    const [failReason, setFailReason] = useState(null);

    function login() {

        console.log(">> login")
        setFailReason(null)
        let isProduction = server === "P";
        Cloud.login(email, password, isProduction, (user) => {

            console.log("<< login", user)

            if (!user || !user.id) {
                setFailReason(user.failReason ? user.failReason : "Unknown error")
                return;
            }

            user.isProduction = isProduction

            setEmail("")
            setPassword("")
            onComplete(user)
        });
    }

    const CustomBackdrop = styled(Backdrop)(({ theme }) => ({
        backgroundColor: 'rgba(255, 255, 255, 1)', // Fully white background
    }));


    return (
        <>

            <Modal
                open={open}
                BackdropComponent={CustomBackdrop}
            >
                <Box sx={UIModal.style}>
                    <h2>Wahoo Logrrator</h2>

                    <FormControl fullWidth>

                        <RadioGroup row value={server} onChange={(event) => {
                            setServer(event.target.value)
                        }}>
                            <FormControlLabel value="P" control={<Radio/>} label="Production"/>
                            <FormControlLabel value="S" control={<Radio/>} label="Staging"/>
                        </RadioGroup>

                        <UIDivider/>
                        <TextField label="Email" value={email} onChange={(event) => {
                            setEmail(event.target.value)
                        }}/>

                        <UIDivider/>
                        <TextField type="password" value={password} onChange={(event) => {
                            setPassword(event.target.value)
                        }}/>

                        <UIDivider/>
                        <div style={{display: 'flex', flexDirection: 'row'}}>
                            <Button
                                fullWidth
                                variant="contained"
                                onClick={() => {
                                    login()
                                }}>Log In</Button>
                        </div>

                        <UIDivider/>
                        {failReason && <label>{failReason}</label>}

                    </FormControl>


                </Box>
            </Modal>


        </>
    );
}

export default UIModalLogin;